$font: Source Sans Pro;

$bodyColor: #F1F4F8;
$boxShadowColor: #00000014;
$darkBlueTableColor: #082D75;
$darkBlueTableShadowColor: #00000029;
$buttonBorderColor: #FFFFFF;
$buttonPrimaryBackgroundColor: #FFFFFF;
$buttonPrimaryColor: #082D75;
$buttonSecondaryBackgroundColor: transparent;
$buttonSecondaryColor: #FFFFFF;
$buttonSecondaryFilter: #135DF5;
$blueTableColor: #25A1FA;
$whiteTextColor: #FFFFFF;
$blackTextColor: #111111;
$grayTextColor: #454A54;
$redTextColor: #E71F2A;
$grayLoadingTextColor: #C6C6C6;
$greenTextColor: #088426;
$blueTextColor: #135DF5;
$greenTableColor: #39B54A;
$modalFilter: #FFFFFF;
$modalFilterFooter: #F4F7FB;
$grayBorderColor: #585858;
$tableEvenLine: #F9F9F9;
$tableOddLine: #FFFFFF;
$tableHeaderColor: #FFFFFF;
$tableHeaderColorDealDetail: #003307;
$tableHeaderColumnSeparatorColor: #dddddd;
$tableBodyColor: #555555;
$tableBodyColumnSeparatorColor: #dddddd;
$tableTitleColor: #FFFFFF;
$inputBorderColor: #FAFAFA;
$inputRangeBorderColor: #454A54;
$inputBlueBorderColor: #135DF5;
$inputLightBgBorderColor: #666666;
$inputLightBgBackgroundColor: #e7e7e7;
$ngValidLeftBorderColor: #42A948;
$ngInvalidLeftBorderColor: #a94442;
$darkBlueContainerColor: #135DF5;
$greenContainerColor: #D1F8EC;
$darkBlueContainerTextColor: #FFFFFF;
$navbarBackgroundColor: #FFFFFF;
$pendingSearchTextColor: #135DF5;
$menuSelectedItemColor: #135DF5;
$menuLinkColor: #666666;
$menuSelectedLinkColor: #FFFFFF;
$menuExtColor: #135DF5;
$menuSubmenuItemBorder: white;
$loginFormBackgroundColor: #FFFFFF;
$loginFormBorderColor: #707070;
$loginMainTextColor: #707070;
$loginInputBorderBottomColor: #0098FF;
$loginLinkColor: #FFFFFF;
$loginButtonBackgroundColor: #0098FF;
$searchButtonBackgroundColor1: #34D9FF;
$searchButtonBackgroundColor2: #135DF5;
$searchButtonBoxShadowColor: #329D9C36;
$calendarBackgroundColor: #FFFFFF;
$calendarTitleColor: #8391DE;
$calendarSelectedBackgroundColor1: #2862B1;
$calendarSelectedBackgroundColor2: #7BE0E4;
$calendarMonthAndYearTextColor: #205072;

$toastrSuccessBackgroundColor: #D9FFDF;
$toastrWarningBackgroundColor: #FFCCCC;
$toastrBoxShadowColor: #003B0E52;
$toastrBorderColor: #EEEEEE;
$toastrColor: #004309;
$toastrWarningColor: #333333;

$spinnerColor: #FFFFFF;
$circleBackgroundColor: #EEEEEE;

$badgeAtivo:#2191F9;
$badgeInativo:#CEAA54;
$badgeConcluido:#38B349;
$badgeANegociar:#2191F9;

$checkboxBackgroundColor: #b9ceff;
$checkboxSliderBackgroundColor: #ffffff;
$checkboxSelectedBackgroundColor: #3542f2;
$checkboxHoverShadowColor: #d7e3fc;
$checkboxDisabledBackgroundColor: #d5d5d5;

$searchButtonBackground: linear-gradient(340deg, $searchButtonBackgroundColor2, $searchButtonBackgroundColor1);
$datePickerSelectedBackground: linear-gradient(340deg, $calendarSelectedBackgroundColor1, $calendarSelectedBackgroundColor2);
  //transparent radial-gradient(closest-side at 6% 0%, $searchButtonBackgroundColor1 0%, $searchButtonBackgroundColor2 100%) 0% 0% no-repeat padding-box !important;
