@import '~bootstrap/dist/css/bootstrap.css';
@import '~ngx-toastr/toastr.css';
@import 'vars';

.top-buffer {
  margin-top: 20px;
}

.right-buffer {
  margin-right: 20px;
}

.left-buffer {
  margin-left: 20px;
}

body {
  background: $bodyColor 0 0 no-repeat padding-box;
  min-width: 1280px;
  opacity: 1;
  margin-bottom: 15px;
}

.login-body {
  background-image: url("../login_bg.png");
  background-size: cover;
}


.large-modal {
  .modal-dialog {
    max-width: 98%;
    width: 98%;
  }
}

.medium-modal {
  .modal-dialog {
    max-width: 40%;
    width: 40%;
  }
}


.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  opacity: 1;
  outline: 0;
  border-radius: 16px;
}

.modal-header {
  box-shadow: 0 16px 48px $boxShadowColor;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.modal-footer {
  box-shadow: 0 16px 48px $boxShadowColor;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.btn-rounded {
  border-radius: 16px;
}

.btn-edit-container {
  padding-left: 20px;
}

.btn-edit {
  border: 2px solid $buttonBorderColor;
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  font: normal normal bold 15px/13px $font;
  letter-spacing: 0.75px;
  color: $buttonPrimaryColor;
  background-color: $buttonPrimaryBackgroundColor;
  text-transform: uppercase;
  padding: 10px 30px;
}

.btn-edit-invert {
  border: 2px solid $buttonBorderColor;
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  font: normal normal bold 15px/13px $font;
  letter-spacing: 0.75px;
  color: $buttonPrimaryBackgroundColor;
  background-color: $buttonPrimaryColor;
  text-transform: uppercase;
  padding: 10px 30px;
}

.btn-edit-cancel {
  background: $buttonSecondaryBackgroundColor;
  border: 2px solid $buttonBorderColor;
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  font: normal normal bold 15px/13px $font;
  letter-spacing: 0.75px;
  color: $buttonSecondaryColor;
  text-transform: uppercase;
  padding: 10px 30px;
}

.btn-edit-cancel-invert {
  background-color: $buttonSecondaryColor;
  border: 2px solid $buttonPrimaryColor;
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  font: normal normal bold 15px/13px $font;
  letter-spacing: 0.75px;
  color: $buttonPrimaryColor;
  text-transform: uppercase;
  padding: 10px 30px;
}

.btn-aplication-filter{
  background-color: $buttonSecondaryFilter;
  border-radius: 32px;
  padding: 22px 32px;
  gap: 4px;
  width: 320px;
  height: 64px;
  color: $whiteTextColor;
  font-size: 20px;
}

.btn-clean-filter{
  background-color: $buttonPrimaryBackgroundColor;
  color: $blueTextColor;
  border: 2px solid $inputBlueBorderColor;
  border-radius: 32px;
  padding: 22px 32px;
  gap: 4px;
  width: 320px;
  height: 64px;
  font-size: 20px;
}

.btn-disabled{
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-loading{
 background-color: $buttonPrimaryColor;
 opacity: 1;
 text-transform: lowercase;
 padding-left: 85px;
 color: $grayLoadingTextColor;
}

.btn-loading::first-letter{
  text-transform: uppercase;
}

.white-icon {
  filter: invert(99%) sepia(1%) saturate(163%) hue-rotate(301deg) brightness(116%) contrast(100%);
}

.remove-icon {
  filter: invert(20%) sepia(85%) saturate(3241%) hue-rotate(345deg) brightness(91%) contrast(99%);
}

.icon-smallest {
  width: 11px;
  height: 11px;
}

.icon-small {
  width: 15px;
  height: 15px;
}

.icon {
  width: 23px;
  height: 23px;
}

.icon-large {
  width: 30px;
  height: 30px;
}

.middle-aligned-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container {
  max-width: 90%;
  width: 90%;
}

.search-box {
  height: 258px;
  background: $darkBlueTableColor 0 0 no-repeat padding-box;
  box-shadow: 16px 16px 32px $darkBlueTableShadowColor;
  border-radius: 0 0 16px 16px;
  opacity: 1;
}

.title-search {
  font: normal normal 600 38px/40px $font;
  letter-spacing: 0;
  color: white;
  opacity: 1;
}

.badge-lg {
  font: normal normal normal 12px $font !important;
  letter-spacing: 0.5px;
  padding: 8px 20px;
  text-transform: uppercase;
}

.break-all {
  word-break: break-all;
}

.table-blue {
  background: $blueTableColor 0 0 no-repeat padding-box;
  box-shadow: 0 16px 48px $boxShadowColor;
  border-radius: 16px 16px 16px 16px;
  width: 100%;
  opacity: 1;
}

.table-green {
  background: $greenTableColor 0 0 no-repeat padding-box;
  box-shadow: 0 16px 48px $boxShadowColor;
  border-radius: 16px 16px 16px 16px;
  width: 100%;
  opacity: 1;
}

.table-dark-blue {
  background: $darkBlueTableColor 0 0 no-repeat padding-box;
  box-shadow: 0 16px 48px $boxShadowColor;
  border-radius: 16px 16px 16px 16px;
  width: 100%;
  opacity: 1;
}

thead {
  tr:last-child {
    height: 15px !important;
  }
}

tbody {
  tr {
    &:first-child {
      td {
        &:first-child {
          border-top-left-radius: 16px;
        }

        &:last-child {
          border-top-right-radius: 16px;
        }
      }
    }

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 16px;
          opacity: 1;
        }

        &:last-child {
          border-bottom-right-radius: 16px;
          opacity: 1;
        }
      }
    }

    &:not(:first-child) {
      border-top: 1px solid $tableBodyColumnSeparatorColor;
    }

    &:nth-child(even) {
      background-color: $tableEvenLine;
    }

    &:nth-child(odd) {
      background-color: $tableOddLine;
    }
  }
}

tr {
  th {
    text-align: left;
    font: normal normal 600 16px $font;
    letter-spacing: 0;
    color: $tableHeaderColor;
    opacity: 1;
    white-space: nowrap;
    padding: 10px 8px 10px 8px;
  }

  th:first-child {
    padding: 10px 5px 10px 15px;
  }

  th:last-child {
    padding: 10px 5px 10px 8px;
  }

  th:not(:first-child) {
    border-left: 1px solid $tableHeaderColumnSeparatorColor;
  }

  td {
    text-align: left;
    font: normal normal normal 14px $font;
    letter-spacing: 0;
    color: $tableBodyColor;
    opacity: 1;
    padding: 10px 8px 10px 8px;
  }

  td:first-child {
    padding: 10px 5px 10px 15px;
  }

  td:last-child {
    padding: 10px 15px 10px 8px;
  }

  td:not(:first-child) {
    border-left: 1px solid $tableBodyColumnSeparatorColor;
  }
}

.table-title {
  text-align: left;
  font: normal normal 600 32px $font;
  letter-spacing: 0;
  color: $tableTitleColor;
  opacity: 1;
  display: table-row;
}

.table-sub-title {
  text-align: left;
  margin-top: 15px;
  font: normal normal normal 20px $font;
  letter-spacing: 0;
  color: $tableTitleColor;
  opacity: 1;
}

input[type=checkbox] {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

.form-input {
  box-shadow: 4px 16px 48px $boxShadowColor;
  border: 1px solid $inputBorderColor;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  width: 100%;
}

.form-input-light {
  box-shadow: 4px 16px 48px $boxShadowColor;
  border: 1px solid $inputBorderColor;
  border-radius: 8px;
  background-color: $inputLightBgBackgroundColor;
  width: 100%;
}

.input-range{
  width: 128px;
  height: 64px;
  box-shadow: 4px 16px 48px $boxShadowColor;
  border: 1px solid $inputRangeBorderColor;
  border-radius: 8px;
  padding: 24px;
}

.form-input-text{ 
  width: 260px;
  height: 64px;
  box-shadow: 4px 16px 48px $boxShadowColor;
  border: 1px solid $inputRangeBorderColor;
  border-radius: 8px;
  padding: 24px;
}

.multiselect-dropdown {
  box-shadow: 4px 16px 48px $boxShadowColor !important;
  border: 1px solid $inputBorderColor !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: white !important;
  width: 100% !important;
}

.dropdown-multiselect__caret:before {
  all: initial !important;
}

.container-title {
  padding: 18px !important;
}

.toast-top-center {
  @extend .toast-top-center;
  position: fixed;
  top: 120px;
}

.toast-success {
  @extend .toast-success;
  background: $toastrSuccessBackgroundColor 0% 0% no-repeat padding-box;
  box-shadow: 4px 16px 48px $toastrBoxShadowColor;
  border: 1px solid $toastrBorderColor;
  border-radius: 8px;
  color: $toastrColor !important;
  opacity: 1;
  width: 900px !important;
  height: 70px !important;
  text-align: center;
}

.toast-warning {
  @extend .toast-success;
  background: $toastrWarningBackgroundColor 0% 0% no-repeat padding-box;
  color: $toastrWarningColor !important;
}

.badge-ativo {
  color: $whiteTextColor;
  background-color: $badgeAtivo;
}

.badge-inativo {
  color: $whiteTextColor;
  background-color: $badgeInativo;
}

.badge-concluido {
  color: $whiteTextColor;
  background-color: $badgeConcluido;
}

.badge-a-negociar {
  color: $whiteTextColor;
  background-color: $badgeANegociar;
}

.lnk {
  text-align: left;
  text-decoration: underline;
  font: normal normal 600 14px/40px Source Sans Pro;
  letter-spacing: 0;
  color: #003307;
  opacity: 1;
}

ngb-datepicker {
  @extend ngb-datepicker;
  padding: 30px 60px !important;
  border-radius: 21px !important;
  border-color: transparent !important;
  background-color: $calendarBackgroundColor;
  top: 10px !important;
  left: -150px !important;
}

[ngbDatepickerDayView] {
  @extend [ngbDatepickerDayView];
  border-radius: 60px !important;
}

[ngbDatepickerDayView].bg-primary {
  background: $datePickerSelectedBackground !important;
}

.ngb-dp-header {
  @extend .ngb-dp-header;
  background-color: transparent !important;
}

.ngb-dp-weekdays {
  @extend .ngb-dp-weekdays;
  border-bottom: 1px dashed $tableBodyColumnSeparatorColor !important;
  background-color: transparent !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ngb-dp-weekday {
  @extend .ngb-dp-weekday;
  margin: 10px !important;
  color: $calendarTitleColor !important;
  text-transform: uppercase;
  font-style: normal !important;
}

.ngb-dp-day {
  @extend .ngb-dp-day;
  margin: 10px !important;
}

.ngb-dp-navigation-chevron {
  @extend .ngb-dp-navigation-chevron;
  color: $calendarMonthAndYearTextColor !important;
  border-width: 2px 2px 0 0 !important;
  width: 10px !important;
  height: 10px !important;
}

.ngb-dp-arrow-btn {
  @extend .ngb-dp-arrow-btn;
  box-shadow: 0 7px 24px $boxShadowColor !important;
  width: 24px !important;
  height: 24px !important;
}

.ngb-dp-navigation-select {
  align-self: stretch !important;

  :first-child {
    margin-left: 50px !important;
  }
}

.custom-select {
  all: unset !important;
  background-color: transparent !important;
  font: normal normal normal 20px $font !important;
  color: $calendarMonthAndYearTextColor !important;
  text-transform: capitalize !important;
  padding: 0 4px !important;
}

.seta-cima:before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  margin-right: 10px;
  width: 0;
  height: 0;

  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid $tableTitleColor;
}

.seta-baixo:before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  margin-right: 10px;
  width: 0;
  height: 0;

  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $tableTitleColor;
}

.cursor{
  cursor: pointer;
}

.itemValidator {
  color: red;
  padding: 6px;
}

.data-title {
  text-align: left;
  font: normal normal normal 20px/40px $font;
  letter-spacing: 0;
  color: $whiteTextColor;
  opacity: 1;
  padding-left: 20px;
  width: 220px;
}

.data-value {
  text-align: left;
  font: normal normal 600 20px/40px $font;
  letter-spacing: 0;
  padding: 2px;
  color: $whiteTextColor;
  opacity: 1;
  width: 450px;
}

.data-title-light {
  text-align: left;
  font: normal normal normal 20px/40px $font;
  letter-spacing: 0;
  opacity: 1;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.data-value-light {
  text-align: left;
  font: normal normal 600 20px/40px $font;
  letter-spacing: 0;
  padding: 2px 20px 2px 20px;
  opacity: 1;
  width: 100%;
}

.row{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 2px;
}
